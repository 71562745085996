import React from "react"

export default function YouTube({ videoId, title }) {
  const youtubeUrl = `https://www.youtube-nocookie.com/embed/${videoId}`
  return (
    <figure>
      <iframe
        width="800"
        height="450"
        src={youtubeUrl}
        title={title}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </figure>
  )
}
