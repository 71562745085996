import React from "react"

import "./styles.scss"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import MarkdownContent from "../components/MarkdownContent"

import { graphql } from "gatsby"

const ErrorPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
        pathname={location.pathname}
      />
      <section className="mt-4">
        <div className="container">
          <MarkdownContent mdx={data.mdx} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }

    mdx(fields: { slug: { eq: "/default/404" } }) {
      body
      frontmatter {
        title
        description
      }
    }
  }
`

export default ErrorPage
