import React from "react"
import { Link } from "gatsby"
import YouTube from "../components/YouTube"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function MarkdownContent({ mdx }) {
  const shortcodes = {
    Link,
    h1: ({ children, ...props }) => (
      <h1 {...props} className="title">
        {children}
      </h1>
    ),
    h2: ({ children, ...props }) => (
      <h2 {...props} className="subtitle">
        {children}
      </h2>
    ),
    YouTube: ({ ...props }) => <YouTube {...props} />,
  }

  return (
    <div className="content">
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </div>
  )
}
